import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { FormGroup, Col, Label, ListGroup, ListGroupItem, Input } from 'reactstrap'

import { number_format } from '../../../izUtils'

const TaskPriceItem = ({index, item, handleItem, permissions, showCheckbox}) => {
    const {method} = useParams();

    const [inputs, setInputs] = useState({
        name: item.name,
        quantity: item.quantity,
        price_per_unit: item.price_per_unit,
        discount: item.discount,
        price_total: item.price_total,
        warranty: item.warranty,
    })

    useEffect(() => {
        setInputs({
            name: item.name,
            quantity: item.quantity,
            price_per_unit: item.price_per_unit,
            discount: item.discount,
            price_total: item.price_total,
            warranty: item.warranty,
        })
    }, [item.name, item.quantity, item.price_per_unit, item.discount, item.warranty])

    const handleChange = (e) => {
        let clonedInputs = {...inputs};
        clonedInputs[e.target.name] = e.target.value;
        setInputs(clonedInputs)
    }

    const handleBlur = (e) => {
        let clonedInputs = {...inputs};
        const toInt = ['quantity', 'discount'];
        const toFloat = ['price_per_unit'];

        let val = e.target.value.replace(/\./g, '');
        if (toFloat.indexOf(e.target.name) !== -1) {
            val = number_format(val.replace(',', '.'), 2, ',', '.');
            clonedInputs[e.target.name] = val
        } else if (toInt.indexOf(e.target.name) !== -1) {
            val = val.split(',');
            clonedInputs[e.target.name] = isNaN(parseInt(val[0])) ? 0 : parseInt(val[0])
        } else {
            clonedInputs[e.target.name] = e.target.value
        }

        setInputs(clonedInputs)
        handleItem(clonedInputs, index)
    }

    const handleCheck = (e) => {
        let clonedInputs = {...inputs};
        clonedInputs[e.target.name] = e.target.checked;
        setInputs(clonedInputs)
        handleItem(clonedInputs, index)
    }

    if ((method !== 'get') && (permissions['prices.update'])) {
        return (
            <FormGroup>
                <div className='d-flex flex-wrap align-items-end'>
                    {showCheckbox &&
                        <div style={{ width: '100px' }} className='pe-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.warranty')}</Label>
                            <div className="custom-control custom-checkbox mb-3 form-check">
                                <div className="form-checkbox-group">
                                    <Input
                                        className={"custom-control-input"}
                                        id={"warranty-"+index}
                                        name={"warranty"}
                                        type="checkbox"
                                        onChange={handleCheck}
                                        checked={inputs.warranty}
                                    />
                                    <Label className="checkmark" htmlFor={"warranty-"+index}></Label>
                                    {/* <Label className="custom-control-label" htmlFor={"warranty-"+index} style={{ cursor: 'pointer' }} >{t('form.label.prices.warranty')}</Label> */}
                                </div>
                            </div>
                        </div>
                    }
                    <Col xs="12" sm="6" md="4" lg="3" className='pe-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.name')}</Label>
                        <input className="form-control" type="text" name={"name"} value={inputs.name} onChange={handleChange} onBlur={handleBlur} />
                    </Col>
                    <Col xs="12" sm="6" md="4" lg="2" className='pe-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.quantity')}</Label>
                        <input className="form-control" name={"quantity"} value={inputs.quantity} onChange={handleChange} onBlur={handleBlur} />
                    </Col>
                    <Col xs="12" sm="6" md="4" lg="2" className='pe-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.price_per_unit')}</Label>
                        <input className="form-control" name={"price_per_unit"} value={inputs.price_per_unit} onChange={handleChange} onBlur={handleBlur} />
                    </Col>
                    <Col xs="12" sm="6" md="4" lg="2" className='pe-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.discount')}</Label>
                        <input className="form-control" name={"discount"} value={inputs.discount} onChange={handleChange} onBlur={handleBlur} />
                    </Col>
                    <Col xs="12" sm="6" md="4" lg="2" className='pe-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.price_total')}</Label>
                        <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-end'>
                            <input className="form-control" name={"price_total"} value={number_format(inputs.price_total, 2, ',', '.')} onChange={handleChange} onBlur={handleBlur} disabled/>
                            <i className="icon-trash bigger-icon ms-0 mt-2 ms-sm-2" onClick={() => handleItem(null, index)}></i>
                        </div>
                    </Col>
                </div>
            </FormGroup>
        )
    } else {
        return (
            <ListGroup className="list-group-flush">
                <ListGroupItem>
                    <div className='d-flex flex-wrap align-items-end'>
                        {showCheckbox &&
                            <Col style={{ width: '100px' }} className='pe-2'>
                                <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.warranty')}</Label>
                                <div className="custom-control custom-checkbox form-check">
                                    <div className="form-checkbox-group">
                                        <Input
                                            className={"custom-control-input"}
                                            id={"warranty-"+index}
                                            name={"warranty"}
                                            type="checkbox"
                                            checked={inputs.warranty}
                                            disabled={true}
                                        />
                                        <Label className="checkmark" htmlFor={"warranty-"+index}></Label>
                                        {/* <Label className="custom-control-label" htmlFor={"warranty-"+index} style={{ cursor: 'pointer' }} >{t('form.label.prices.warranty')}</Label> */}
                                    </div>
                                </div>
                            </Col>
                        }
                        <Col xs="12" sm="6" md="4" lg="3" className='pe-2'>
                            <Label className={"display-label d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.name')}</Label>
                            <div className="display-input">{inputs.name}</div>
                        </Col>
                        <Col xs="12" sm="6" md="4" lg="2" className='pe-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.quantity')}</Label>
                            <div className="display-input">{inputs.quantity}</div>
                        </Col>
                        <Col xs="12" sm="6" md="4" lg="2" className='pe-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.price_per_unit')}</Label>
                            <div className="display-input">{inputs.price_per_unit}</div>
                        </Col>
                        <Col xs="12" sm="6" md="4" lg="2" className='pe-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.discount')}</Label>
                            <div className="display-input">{inputs.discount}</div>
                        </Col>
                        <Col xs="12" sm="6" md="4" lg="2" className='pe-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.price_total')}</Label>
                            <div className="display-input">{number_format(inputs.price_total, 2, ',', '.')}</div>
                        </Col>
                    </div>
                </ListGroupItem>
            </ListGroup>
        )
    }

}

export default TaskPriceItem